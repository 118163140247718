import React, { useEffect, useState } from "react";
import ProductCard from "./common/ProductCard";
import { httpReauest } from "../utils/httpRequest";
import Loading from "./common/Loading";
import ProductCardHome from "./common/ProductCardHome";
import Reveal from "./motion/Reveal";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";

const BestProducts = () => {
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(true);

  async function getData(params) {
    setloading(true);
    const res = await httpReauest("GET", "/product/best", {}, {});
    setData(res.data.data);
    setloading(false);
  }

  useEffect(() => {
    getData();
  }, []);

  const breakpoints = {
    1024: {
      slidesPerView: 4,
      spaceBetween: 16,
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 16,
    },
    680: {
      slidesPerView: 3,
      spaceBetween: 8,
    },
    0: {
      slidesPerView: 2,
      spaceBetween: 8,
    },
  };

  return (
    <section className=" layout_padding2 text-center clients2">
      <div className="container">
        <Reveal head>
          <div className="brand_heading">
            <h3 className="custom_heading text-[1.75rem] ">محصولات پیشنهادی</h3>
          </div>
        </Reveal>
      </div>
      <div className="px-[10px] sm:px-10 lg:px-20 xl:px-36">
        {loading ? (
          <Loading />
        ) : (
          <Swiper
            modules={[Autoplay, Pagination]}
            autoplay={{ delay: 3000 }}
            pagination={{
              el: ".swiper-pagination",
              type: "bullets",
              clickable: true,
            }}
            breakpoints={breakpoints}
            dir="ltr"
            loop={true}
            style={{paddingTop:"1.5rem"}}
          >
            {data.map((item) => (
              <SwiperSlide>
                <ProductCardHome data={item} />
              </SwiperSlide>
            ))}
            <div class="swiper-pagination"></div>
          </Swiper>
        )}
      </div>
    </section>
  );
};

export default BestProducts;
