import React from "react";
import { Link } from "react-router-dom";

import {
  FaInstagram,
  FaFacebookF,
  FaTwitter,
  FaYoutube,
  FaLinkedinIn,
} from "react-icons/fa";
import { FaTelegram, FaWhatsapp } from "react-icons/fa6";
const Footer = () => {
  return (
    <>
      <footer dir="rtl" class="bg-zinc-950 text-right text-white">
        <div class="px-[12px] text-[14px] lg:px-16 py-10 text-center md:text-left">
          <div class="grid-1 grid gap-8 md:grid-cols-2 lg:grid-cols-4 text-right">
            <div>
              <h6 class="mb-4 flex items-center justify-start font-semibold text-[18px] uppercase md:justify-start">
                دکتر گلد
              </h6>
              <p>
                دکترگلد تنها استراتژیست منیجر در صنعت طلا جواهرات با رویکرد
                کوچینگی از آموزش فروشندگی تا درآمد میلیاردی و مسیر بین المللی با
                آکادمی دکتر گلد
              </p>
            </div>
            <div>
              <h6 class="mb-4 flex justify-start font-semibold text-[18px] uppercase md:justify-start">
                لینک های اصلی
              </h6>
              <p class="mb-4">
                <Link to={"/"}>خانه</Link>
              </p>
              <p class="mb-4">
                <Link to={"/about"}>درباره ما</Link>
              </p>
              <p class="mb-4">
                <Link to={"/products"}>محصولات</Link>
              </p>
              <p>
                <Link to={"/contact"}>ارتباط با ما</Link>
              </p>
            </div>
            <div>
              <h6 class="mb-4 flex justify-start font-semibold text-[18px] uppercase md:justify-start">
                خدمات مشتریان
              </h6>
              <p class="mb-4">
                <Link to={"/login"}>پروفایل</Link>
              </p>
              <p class="mb-4">
                <Link to={"/terms-and-conditions"}>قوانین و مقررات</Link>
              </p>
              <p class="mb-4">
                <Link to={"/purchase-guide"}>راهنمای خرید</Link>
              </p>
              <p class="mb-4">
                <Link to={"/blog/راهنمای تعیین سایز"}>راهنمای تعیین سایز</Link>
              </p>
              <p class="mb-4">
                <Link to={"/faq"}>سوالات متداول</Link>
              </p>
              <p>
                <Link to={"/login"}>پیگیری ارسال سفارش</Link>
              </p>
            </div>
            <div>
              <h6 class="mb-4 flex justify-start font-semibold text-[18px] uppercase md:justify-start">
                ارتباط
              </h6>
              <p class="mb-4 flex items-center justify-start md:justify-start">
                <span class="me-3 [&>svg]:h-5 [&>svg]:w-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path d="M11.47 3.84a.75.75 0 011.06 0l8.69 8.69a.75.75 0 101.06-1.06l-8.689-8.69a2.25 2.25 0 00-3.182 0l-8.69 8.69a.75.75 0 001.061 1.06l8.69-8.69z" />
                    <path d="M12 5.432l8.159 8.159c.03.03.06.058.091.086v6.198c0 1.035-.84 1.875-1.875 1.875H15a.75.75 0 01-.75-.75v-4.5a.75.75 0 00-.75-.75h-3a.75.75 0 00-.75.75V21a.75.75 0 01-.75.75H5.625a1.875 1.875 0 01-1.875-1.875v-6.198a2.29 2.29 0 00.091-.086L12 5.43z" />
                  </svg>
                </span>
                اصفهان چهارباغ بالا کوچه ۲۳ پلاک ۱۰۱
              </p>
              <Link
                to="tel:09131300319"
                dir="ltr"
                class="mb-4 flex items-center justify-end md:justify-end"
              >
                0913 130 0319
                <span class="ms-3 [&>svg]:h-5 [&>svg]:w-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </Link>
              <div class="flex justify-start  gap-5">
                <Link
                  to="https://t.me/drgoldco"
                  target="_blank"
                  class="text-gray-300 hover:text-gray-300"
                >
                  <FaTelegram size={26} />
                </Link>
                <Link
                  to="https://www.instagram.com/dr.g.o.l.d?igsh=emE2cHFuOXM4ZDY3"
                  target="_blank"
                  class="text-gray-300 hover:text-gray-300"
                >
                  <FaInstagram size={26} />
                </Link>
                <Link
                  to="https://wa.me/989369650913"
                  class="text-gray-300 hover:text-gray-300"
                >
                  <FaWhatsapp size={26} />
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div class="bg-black/5 p-6 text-center">
          <p class="mt-8 text-base leading-6 text-center text-gray-500">
            تمامی حقوق محفوظ است
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
