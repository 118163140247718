import React from "react";
import RevealX from "./motion/RevealX";
import Reveal from "./motion/Reveal";

const WhySection = () => {
  return (
    <section dir="rtl" className="why_section layout_padding text-right">
      <div className="container ">
        <RevealX head>
          <h3 className="custom_heading text-[1.75rem]">چرا دکتر گلد</h3>
        </RevealX>
        <Reveal>
          <p className=" mt-1">
            دکتر گلد، فروشگاه آنلاین طلا و جواهر با سابقه و اعتبار قابل اعتماد.
            ما همواره بر ارائه بهترین محصولات با کیفیت و خدمات مشتری‌مدارانه
            تمرکز داشته‌ایم. با خرید از دکتر گلد، می‌توانید در امنیت خاطر و با
            اطمینان، محصولات گرانبهای خود را تهیه کنید.
          </p>
        </Reveal>
      </div>
      <div className="container">
        <div className="row">
          <div className=" col-md-6">
            <RevealX head>
              <div className="img_box">
                <img src="images/feature-11.png" alt="" />
              </div>
            </RevealX>
            <Reveal>
              <div className="detail_box">
                <h5>تحویل سریع</h5>
                <p>
                  دکتر گلد، فروشگاه آنلاین طلا و جواهر با بهترین خدمات ارسال
                  محصولات، ارسال رایگان و تحویل سریع به سراسر کشور. تجربه خرید
                  آنلاین راحت و لذت بخش را با ما تجربه کنید.
                </p>
              </div>
            </Reveal>
          </div>
          <div className=" col-md-6">
            <RevealX head>
              <div className="img_box">
                <img src="images/feature-2.png" alt="" />
              </div>
            </RevealX>
            <Reveal>
              <div className="detail_box">
                <h5>ارسال رایگان</h5>
                <p>
                  در فروشگاه طلا و جواهر دکتر گلد، ارسال محصولات به سراسر ایران
                  به صورت رایگان انجام می‌شود. این امکان، خرید آسان و مقرون به
                  صرفه را برای شما فراهم می‌کند.
                </p>
              </div>
            </Reveal>
          </div>
          <div className=" col-md-6">
            <RevealX head>
              <div className="img_box">
                <img src="images/feature-3.png" alt="" />
              </div>
            </RevealX>
            <Reveal>
              {" "}
              <div className="detail_box">
                <h5>بهترین کیفیت</h5>
                <p>
                  دکتر گلد، فروشگاه معتبر طلا و جواهر، همواره بر کیفیت محصولات
                  خود تاکید دارد. اطمینان از بهترین کیفیت طلا و جواهرات در هر
                  خرید از ما، یکی از مزیت‌های انتخاب فروشگاه دکتر گلد است.
                </p>
              </div>
            </Reveal>
          </div>
          <div className=" col-md-6">
            <RevealX head>
              <div className="img_box">
                <img src="images/feature-4.png" alt="" />
              </div>
            </RevealX>
            <Reveal>
              <div className="detail_box">
                <h5>پشتیبانی شبانه روزی</h5>
                <p>
                  تیم پشتیبانی دکتر گلد، همه روزه و در تمام ساعات شبانه‌روز
                  آماده پاسخگویی به سوالات شما هستند. هر زمان که نیاز به
                  راهنمایی داشته باشید، ما در کنار شما خواهیم بود.
                </p>
              </div>
            </Reveal>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhySection;
