import React from "react";
import Header from "../components/Header";
import SliderSection from "../components/Slider";
import AboutSection from "../components/AboutSection";
import WhySection from "../components/WhySection";
import TestimonialSection from "../components/Testmonials";
import ContactUs from "../components/ContactUs";
import InfoSection from "../components/InfoSection";
import TeamComponent from "../components/TeamSection";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import { RxShare2 } from "react-icons/rx";
import { VscDiffAdded } from "react-icons/vsc";
import { BsAndroid, BsAndroid2, BsApple } from "react-icons/bs";
import { CiMenuKebab } from "react-icons/ci";
import { MdInstallMobile } from "react-icons/md";
const InstallAppPage = () => {
  return (
    <div className="hero_area">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"دکتر گلد | نصب اپ"}</title>
        <meta
          name="description"
          content={
            "شرکت طلا و جواهر دکتر گلد تولیدکننده مدلهای فانتزی در ایران ( بازار تهران) آماده همکاری با ویترین داران و پخش"
          }
        />
        <meta
          name="og:description"
          content={
            "شرکت طلا و جواهر دکتر گلد تولیدکننده مدلهای فانتزی در ایران ( بازار تهران) آماده همکاری با ویترین داران و پخش"
          }
        />
        <meta name="og:title" content={"دکتر گلد | نصب اپ"} />
      </Helmet>
      <Header />
      <div>
        <div
          dir="rtl"
          className="h-full w-full flex flex-col justify-center bg-white py-16 px-[1rem]"
        >
          <div
            className="mx-auto"
            style={{ width: "136px", height: "124px", lineHeight: "0" }}
          >
            <img
              className="w-full inline-block"
              src="/logo.png"
              width="136"
              height="124"
              alt=""
              title=""
              style={{ objectFit: "contain" }}
            />
          </div>
          <div className="w-full mt-10">
            <div className="text-subtitle-strong mb-2 text-right">
              وب‌اپلیکیشن دکتر گلد را به صفحه اصلی گوشی‌تان اضافه کنید.
            </div>
            <div className="font-bold mb-2 text-right flex gap-1 items-center">
              کاربران ios <BsApple /> :
            </div>
            <div className="bg-neutral-000 border p-[1rem] text-[14px] text-right flex flex-col justify-center border-complete-200 rounded  ">
              <div className="flex mb-1">
                <p>۱. در نوار پایین دکمه</p>
                <div className="flex mx-1">
                  <RxShare2 color="lightblue" size={25} />
                </div>
                <p>«Share» را انتخاب کنید.</p>
              </div>
              <div className="flex mb-1">
                <p>۲.</p>
                <div className="flex flex-wrap mr-1">
                  <p className="whitespace-nowrap">در منو باز شده، گزینه ,</p>
                  <div className="flex mx-1">
                    <VscDiffAdded size={21} />
                  </div>
                  <p className="whitespace-nowrap">«Add to home screen»</p>
                  <p>را انتخاب کنید</p>
                </div>
              </div>
              <p>۳. در مرحله بعد “Add” را انتخاب کنید</p>
            </div>
            <div className="font-bold mb-2 text-right flex gap-1 items-center mt-10">
              کاربران اندروید <BsAndroid2 /> :
            </div>
            <div className="bg-neutral-000 border p-[1rem] text-[14px] text-right flex flex-col justify-center border-complete-200 rounded  ">
              <div className="flex mb-1">
                <p>۱. در نوار بالا سمت راست دکمه</p>
                <div className="flex">
                  <CiMenuKebab size={21} />
                </div>
                <p>را انتخاب کنید.</p>
              </div>
              <div className="flex mb-1">
                <p>۲.</p>
                <div className="flex flex-wrap mr-1">
                  <p className="whitespace-nowrap">در منو باز شده، گزینه ,</p>
                  <div className="flex mx-1">
                    <MdInstallMobile />
                  </div>
                  <p className="whitespace-nowrap">«install App»</p>
                  <p>را انتخاب کنید</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <InfoSection /> */}
      <Footer />
    </div>
  );
};

export default InstallAppPage;
