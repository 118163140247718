import React from "react";
import Header from "../components/Header";
import SliderSection from "../components/Slider";
import AboutSection from "../components/AboutSection";
import WhySection from "../components/WhySection";
import TestimonialSection from "../components/Testmonials";
import ContactUs from "../components/ContactUs";
import InfoSection from "../components/InfoSection";
import TeamComponent from "../components/TeamSection";
import Footer from "../components/Footer";
import { Helmet } from "react-helmet";
import Banners2 from "../components/Banners2";

const Contactpage = () => {
  return (
    <div className="hero_area">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"دکتر گلد | ارتباط با ما"}</title>
        <meta
          name="description"
          content={
            "شرکت طلا و جواهر دکتر گلد تولیدکننده مدلهای فانتزی در ایران ( بازار تهران) آماده همکاری با ویترین داران و پخش"
          }
        />
        <meta
          name="og:description"
          content={
            "شرکت طلا و جواهر دکتر گلد تولیدکننده مدلهای فانتزی در ایران ( بازار تهران) آماده همکاری با ویترین داران و پخش"
          }
        />
        <meta name="og:title" content={"دکتر گلد | ارتباط با ما"} />
      </Helmet>
      <Header />

      <ContactUs />
      {/* <InfoSection /> */}
      <Banners2/>

      <Footer />
    </div>
  );
};

export default Contactpage;
