import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { httpReauest } from "../utils/httpRequest";

const CompleteForm = ({ data, setcompeleteForm }) => {
  const [states, setStates] = useState();
  const [cities, setCities] = useState();
  const [previewImg, setPreviewImg] = useState();
  const [img, setImg] = useState();

  function handleImg(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImg(fils);
      setPreviwImage(fils);
    }
  }

  async function setPreviwImage(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  async function getData() {
    await fetch("https://iran-locations-api.ir/api/v1/fa/states").then(
      async (res) => {
        const data = await res.json();
        setStates(data);
      }
    );
  }

  useEffect(() => {
    getData();
  }, []);

  const formik = useFormik({
    initialValues: {
      name: data?.name ? data?.name : "",
      phone: data?.phone ? data?.phone : "",
      address: data?.address ? data?.address : "",
      state: data?.state ? data?.state : "",
      city: data?.city ? data?.city : "",
      postalCode: data?.postalCode ? data?.postalCode : "",
      nationalCode: data?.nationalCode ? data?.nationalCode : "",
    },
    onSubmit: async (values) => {
      const user = JSON.parse(localStorage.getItem("user"));

      await httpReauest("POST", "/user/info/" + user._id, values, {
        "x-access-token": user?.token,
      })
        .then(() => {
          toast.success("موفقیت آمیز");
          formik.resetForm();
          setcompeleteForm(false)
        })
        .catch((err) => {
          toast.error(err?.response?.data?.message);
          formik.resetForm();
        });
    },
  });

  async function getCitis() {
    await fetch(
      "https://iran-locations-api.ir/api/v1/fa/cities?state=" +
        formik.values.state
    ).then(async (res) => {
      const data = await res.json();
      setCities(data[0].cities);
    });
  }
  useEffect(() => {
    getCitis();
  }, [formik.values.state]);

  return (
    <form onSubmit={formik.handleSubmit} dir="rtl" class=" lg:mt-0">
      <button
        type="button"
        onClick={() => setcompeleteForm(false)}
        class="mb-4 rounded-md bg-gray-900 px-4 py-2 font-medium text-white"
      >
        برگشت
      </button>
      <p class="text-gray-900 font-bold">اطلاعات خود را کامل کنید</p>

      <div class="">
        <div className="grid md:grid-cols-2 gap-2">
          <span>
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              نام و نام خانوادگی
            </label>
            <div class="relative">
              <input
                type="text"
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </span>
          <span>
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              شماره تماس
            </label>
            <div class="relative">
              <input
                disabled
                type="text"
                id="phone"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </span>
          <span>
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              کد ملی
            </label>
            <div class="relative">
              <input
                type="text"
                id="nationalCode"
                name="nationalCode"
                value={formik.values.nationalCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </span>
          <span>
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              کد پستی
            </label>
            <div class="relative">
              <input
                type="text"
                id="postalCode"
                name="postalCode"
                value={formik.values.postalCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </span>
        </div>
        <div className="grid md:grid-cols-2 gap-2">
          <span>
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              استان
            </label>
            <div class="relative">
              <select
                id="state"
                name="state"
                value={formik.values.state}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              >
                <option></option>
                {states?.map((e) => (
                  <option>{e.name}</option>
                ))}
              </select>
            </div>
          </span>
          <span>
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              شهر
            </label>
            <div class="relative">
              <select
                id="city"
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={formik.values.state === ""}
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              >
                <option></option>
                {cities?.map((e) => (
                  <option>{e.name}</option>
                ))}
              </select>
            </div>
          </span>
          <span className="col-span-full">
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              آدرس
            </label>
            <div class="relative">
              <textarea
                type="text"
                id="address"
                name="address"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </span>

          <input
            onChange={(e) => {
              handleImg(e);
            }}
            type="file"
            className="hidden"
            id="img"
            accept="image/png, image/jpg, image/jpeg"
          />
        </div>
      </div>
      <button
        type="submit"
        class="mt-4 w-full rounded-md bg-gray-900 px-6 py-3 font-medium text-white"
      >
        ارسال
      </button>
    </form>
  );
};

export default CompleteForm;
