import React from "react";
import Reveal from "./motion/Reveal";

const ContactUs = () => {
  return (
    <>
      <section class="contact_section py-[2rem]">
        <Reveal head>
          <h2 class="custom_heading text-center text-[2rem]">
            همین الان ارتباط بگیر
          </h2>
        </Reveal>

        <div class="container mx-auto md:px-16 lg:px-40 mt-[1rem] pt-[1rem]">
          <form dir="rtl" action="">
            <Reveal>
              <div className="flex lg:flex-row flex-col gap-[1rem]">
                <input type="text" placeholder="نام" />
                <input type="email" placeholder="ایمیل" />
              </div>
            </Reveal>

            <Reveal>
              <div>
                <input type="text" class="message-box" placeholder="پیام" />
              </div>
            </Reveal>
            <Reveal head>
              <div class="flex justify-center mt-[1rem] pt-[1rem]">
                <button>ارسال</button>
              </div>
            </Reveal>
          </form>
        </div>
      </section>
    </>
  );
};

export default ContactUs;
