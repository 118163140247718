export function calculateGoldPrice(goldPrice, weight, makingFee) {
  const profit = 7;
  const makingFeePercentage = makingFee / 100;
  const profitPercentage = profit / 100;
  const totalPrice = goldPrice * (1 + profitPercentage);
  const fixedPrice = totalPrice * weight;
  const priceWith = fixedPrice * (1 + makingFeePercentage);
  const withzeroPrice = Math.floor(priceWith / 1000) * 1000;

  return withzeroPrice;
}
