import React, { useEffect, useState } from "react";
import { FaCartShopping } from "react-icons/fa6";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { httpReauest } from "../utils/httpRequest";
import CompleteForm from "../components/CompleteForm";
import { priceComma } from "../utils/priceComma";
import { CDN_BASE_URL } from "../config";
import CustomModal from "../components/common/Modal";
import { FaTimes } from "react-icons/fa";
import { Shamsi } from "basic-shamsi";
import { Helmet } from "react-helmet";

const DashboardPage = () => {
  const [data, setData] = useState();
  const [orders, setorders] = useState([]);
  const [compeleteForm, setcompeleteForm] = useState(false);
  const [show, setShow] = useState(false);
  const [selected, setselcted] = useState();

  const handleClose = () => setShow(false);
  const handleShow = (data) => {
    setselcted(data);
    setShow(true);
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (!user?.token) {
      window.location.href = window.origin + "/signup";
    }
    getData();
  }, [compeleteForm]);

  async function getData() {
    const user = JSON.parse(localStorage.getItem("user"));
    const newData = await httpReauest(
      "GET",
      "/user/info/" + user?._id,
      {},
      { "x-access-token": user?.token }
    ).catch(() => {
      localStorage.setItem("user", null);
      window.location.href = window.origin + "/signup";
    });

    setData(newData.data.data.user);
    setorders(newData.data.data.orders);
  }
  return (
    <div className="hero_area">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"دکتر گلد | پنل کاربری"}</title>
        <meta
          name="description"
          content={
            "شرکت طلا و جواهر دکتر گلد تولیدکننده مدلهای فانتزی در ایران ( بازار تهران) آماده همکاری با ویترین داران و پخش"
          }
        />
        <meta
          name="og:description"
          content={
            "شرکت طلا و جواهر دکتر گلد تولیدکننده مدلهای فانتزی در ایران ( بازار تهران) آماده همکاری با ویترین داران و پخش"
          }
        />
        <meta name="og:title" content={"دکتر گلد | پنل کاربری"} />
      </Helmet>
      <CustomModal show={show} onHide={handleClose} fit>
        <div className="flex justify-between gap-4 items-center sm:min-w-[500px] w-full">
          <h3 className="whitespace-nowrap">سفارش #{selected?._id}</h3>
          <button onClick={handleClose}>
            <FaTimes />
          </button>
        </div>
        <div className="mt-3">
          <div className="border rounded-[8px] shadow-md p-2">
            <span className="flex justify-start p-2 gap-1">
              وضعیت سفارش:
              {selected?.status == "تایید نشده" ? (
                <span className="bg-red-500 text-white px-3 rounded-[8px]">
                  تایید نشده
                </span>
              ) : selected?.status == "تایید شده منتظر ارسال" ? (
                <span className="bg-yellow-500 text-white px-3 rounded-[8px]">
                  تایید شده منتظر ارسال
                </span>
              ) : (
                <span className="bg-green-500 text-white px-3 rounded-[8px]">
                  ارسال شده
                </span>
              )}
            </span>
            <span className="p-2 flex justify-between flex-wrap gap-3">
              <span>نام و نام خانوادگی :</span>
              <span dir="ltr">{selected?.name}</span>
            </span>
            <span className="p-2 flex justify-between flex-wrap gap-3">
              <span>کد ملی:</span>
              <span dir="ltr">{selected?.nationalCode}</span>
            </span>{" "}
            <span className="p-2 flex justify-between flex-wrap gap-3">
              <span>شماره تماس:</span>
              <span dir="ltr">{selected?.phone}</span>
            </span>
            <span className="p-2 flex justify-between flex-wrap gap-3">
              <span>دسته بندی:</span>
              <span dir="ltr">
                {selected?.packing === "free" ? "عادی" : "مخصوص دکتر گلد"}
              </span>
            </span>
            <span className="p-2 flex justify-between flex-wrap gap-3">
              <span>استان:</span>
              <span dir="ltr">{selected?.state}</span>
            </span>
            <span className="p-2 flex justify-between flex-wrap gap-3">
              <span>شهر:</span>
              <span dir="ltr">{selected?.city}</span>
            </span>{" "}
            <span className="p-2 flex justify-between flex-wrap gap-3">
              <span>کد پستی:</span>
              <span dir="ltr">{selected?.postalCode}</span>
            </span>{" "}
            <span className="p-2 flex justify-between flex-wrap gap-3">
              <span>تاریخ سفارش :</span>
              <span dir="ltr">
                {" "}
                {Shamsi.format(
                  "yyyy/MM/dd - hh:mm",
                  new Date(selected?.createdAt)
                )}
              </span>
            </span>
            <span className="p-2 flex">
              <span>تصویر چک:(برای مشاهده دقیق رویه عکس کلیک کنید)</span>
              <a
                href={CDN_BASE_URL + selected?.img}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="w-20"
                  src={CDN_BASE_URL + selected?.img}
                  alt=""
                />
              </a>
            </span>{" "}
            <span className="p-2 flex">
              <span>آدرس:</span>
              <span dir="ltr">{selected?.address}</span>
            </span>{" "}
            <span className="p-2">
              <span className="font-bold block">صورت حساب:</span>
              <div dir="ltr" className="flex flex-col gap-1 mt-2 border p-2">
                {selected?.products?.map((item) => (
                  <div class=" border-t border-b py-2">
                    <p class="text-sm text-right font-medium text-gray-900 my-2">
                      {item.name}
                    </p>

                    <div class="flex items-center justify-between">
                      <p class="font-semibold text-gray-900" dir="rtl">
                        {priceComma(item?.price, 2)} تومان
                      </p>
                      <p class="text-sm font-medium text-gray-900">
                        قیمت محصول
                      </p>
                    </div>
                    <div class="flex items-center justify-between">
                      <p class="font-semibold text-gray-900">{item.count}</p>
                      <p class="text-sm font-medium text-gray-900">تعداد</p>
                    </div>
                  </div>
                ))}
                <div class=" border-t border-b py-2">
                  <div class="flex items-center justify-between">
                    <p class="font-semibold text-gray-900" dir="rtl">
                      {selected?.packing === "free"
                        ? "رایگان"
                        : "200/000 تومان"}
                    </p>
                    <p class="text-sm font-medium text-gray-900">
                      هزینه دسته بندی{" "}
                    </p>
                  </div>
                </div>
                <div class=" border-t border-b py-2">
                  <div class="flex items-center justify-between">
                    <p class="font-semibold text-gray-900" dir="rtl">
                      {priceComma(selected?.allPrice, 2)} تومان
                    </p>
                    <p class="text-sm font-medium text-gray-900">مجموع</p>
                  </div>
                </div>
              </div>{" "}
            </span>{" "}
          </div>
        </div>
      </CustomModal>
      <Header />
      <div
        dir="rtl"
        className="py-6 lg:py-28  flex justify-center bg-white text-right"
      >
        <div className=" w-full container px-[8px] lg:px-10">
          <div className="lg:grid-cols-4 grid gap-5">
            <span className="py-7 border rounded-md flex flex-col items-center justify-center gap-1 h-fit">
              <span className="rounded-full p-3 bg-orange-500">
                <FaCartShopping size={30} color="white" />
              </span>
              <span className="text-[40px]">{orders.length}</span>
              <span>تعداد سفارشات </span>
            </span>

            <span className=" p-[10px] lg:p-6 border rounded-md lg:col-span-3">
              {compeleteForm ? (
                <>
                  {" "}
                  <CompleteForm
                    data={data}
                    setcompeleteForm={setcompeleteForm}
                  />
                </>
              ) : (
                <>
                  <div className="">
                    <h2 className="font-bold text-xl">اطلاعات من</h2>
                    <div className="grid lg:grid-cols-3 gap-3 my-3">
                      <div className=" ">
                        <span className="font-bold">نام و نام خانوادگی</span>
                        <p className="text-gray-500">{data?.name}</p>
                      </div>
                      <div className=" ">
                        <span className="font-bold">شماره موبایل</span>
                        <p>{data?.phone}</p>
                      </div>
                      <div className=" ">
                        <span className="font-bold">کد پستی</span>
                        <p>{data?.postalCode ? data?.postalCode : "_____"}</p>
                      </div>
                      <div className=" ">
                        <span className="font-bold">کد ملی</span>
                        <p>
                          {data?.nationalCode ? data?.nationalCode : "_____"}
                        </p>
                      </div>
                      <div className=" ">
                        <span className="font-bold">استان</span>
                        <p>{data?.state ? data?.state : "_____"}</p>
                      </div>
                      <div className=" ">
                        <span className="font-bold">شهر</span>
                        <p>{data?.city ? data?.city : "_____"}</p>
                      </div>
                      <div className="col-span-full">
                        <span className="font-bold">آدرس</span>
                        <p>{data?.address ? data?.address : "_____"}</p>
                      </div>
                      <div className="col-span-full">
                        <button
                          onClick={() => setcompeleteForm(true)}
                          className="bg-black p-1 rounded-lg text-white"
                        >
                          تغییر اطلاعات
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="my-10">
                    <h2 className="font-bold text-lg">سفارشات اخیر</h2>
                    {orders?.map((item) => (
                      <div className="border p-[12px] rounded-md mt-3">
                        <div className="flex justify-between">
                          {item?.status == "تایید نشده" ? (
                            <span className="bg-red-500 text-white px-3 rounded-[8px]">
                              تایید نشده
                            </span>
                          ) : item?.status == "تایید شده منتظر ارسال" ? (
                            <span className="bg-yellow-500 text-white px-3 rounded-[8px]">
                              تایید شده منتظر ارسال
                            </span>
                          ) : (
                            <span className="bg-green-500 text-white px-3 rounded-[8px]">
                              ارسال شده
                            </span>
                          )}
                          <span className="text-[14px] lg:text-[16px]">
                            #{item._id}
                          </span>
                        </div>
                        <div className="flex justify-between mt-3 items-end flex-wrap gap-3">
                          <span className=" flex gap-2 flex-wrap">
                            {item?.products?.map((pro) => (
                              <span className="flex flex-col gap-1 items-center border p-1">
                                <img
                                  src={CDN_BASE_URL + pro?.img}
                                  alt={pro.name}
                                  className="w-16 "
                                />
                                <span>{pro.count}x</span>
                                <span> {priceComma(pro?.price, 2)} تومان</span>
                              </span>
                            ))}
                          </span>
                          <span className="font-bold text-[22px]">
                            {priceComma(item?.allPrice, 2)} تومان
                            <button
                              onClick={() => handleShow(item)}
                              className="text-blue-400 text-sm"
                            >
                              بیشتر...
                            </button>
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </span>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default DashboardPage;
