import React from "react";

const TeamComponent = () => {
  return (
    <section className="text-gray-700 body-font border-t border-gray-200">
      <div className="container px-[10px] py-24 mx-auto">
        <div className="flex flex-col text-center w-full mb-10">
          <h1 className="sm:text-3xl text-2xl font-bold title-font mb-4 text-gray-900">
            تیم ما
          </h1>
        </div>
        <div className="flex flex-wrap -m-2">
          <div className="p-2 lg:w-1/2 md:w-1/2 w-full">
            <div className="h-full gap-4 flex flex-col items-center border-gray-200 border p-4 rounded-lg">
              <img
                alt="team"
                className=" bg-gray-100 object-cover object-center w-1/2 rounded-[10px]"
                src="about.JPG"
              />
              <div className="flex-grow text-center">
                <h2 className="text-gray-900 text-[24px] font-bold">
                  سعید امیری
                </h2>
                <p className="text-gray-500">مدیر هولدینگ دکتر گلد</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamComponent;
