import React, { useState } from "react";
import { httpReauest } from "../utils/httpRequest";
import { toast } from "react-toastify";

const BeInIntract = ({ productId }) => {
  const [step, setstep] = useState(false);
  const [phone, setphone] = useState();
  const [error, seterror] = useState();

  async function handleSubmit(e) {
    e.preventDefault();
    if (phone?.length !== 11) {
      return seterror("فرمت شماره صحیح نیست");
    }

    await httpReauest("POST", "/product/in-touch", { phone, productId }, {})
      .then((res) =>
        res.status === 201 ? toast.success("شماره شما ثبت شد") : null
      )
      .catch((err) => toast.error(err?.response?.data?.message));
  }
  return (
    <div>
      {step ? (
        <form onSubmit={handleSubmit} className="flex gap-2 items-start">
          <span>
            <input
              onChange={(e) => setphone(e.target.value)}
              placeholder="099********"
              className="form-control"
            />
            {error && <small className="text-red-500">{error}</small>}
          </span>

          <button className="bg-black rounded text-white px-6 py-2">ثبت</button>
        </form>
      ) : (
        <button onClick={() => setstep(true)} className="slider-btn1">
          موجود شد خبرم کن
        </button>
      )}
    </div>
  );
};

export default BeInIntract;
