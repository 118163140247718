import logo from "./logo.svg";
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import {
  createBrowserRouter,
  Link,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import Homepage from "./pages/Homepage";
import Aboutpage from "./pages/Aboutpage";
import Contactpage from "./pages/Contactpage";
import Productspage from "./pages/Productspage";
import ProductDetailsPage from "./pages/ProductDetailsPage";
import CheckoutPage from "./pages/CheckoutPage";
import { CartContext } from "./context/CartContext";
import { useEffect, useState } from "react";
import CartPage from "./pages/CartPage";
import { httpReauest } from "./utils/httpRequest";
import LoginPage from "./pages/LoginPage";
import SignUpPage from "./pages/SignUpPage";
import DashboardPage from "./pages/DashboardPage";
import TermsPage from "./pages/TermsPage";
import FAQPage from "./pages/FAQPage";
import BlogDetailspage from "./pages/BlogDetailspage";
import Howbuypage from "./pages/Howbuypage";
import ScrollToTop from "./utils/ScrooltoTop";
import InstallAppPage from "./pages/InstallAppPage";
import { FaWhatsapp } from "react-icons/fa6";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <>
          <ScrollToTop />
          <Homepage />
        </>
      ),
    },
    {
      path: "/about",
      element: (
        <>
          <ScrollToTop />
          <Aboutpage />
        </>
      ),
    },
    {
      path: "/contact",
      element: (
        <>
          <ScrollToTop />
          <Contactpage />
        </>
      ),
    },
    {
      path: "/products",
      element: (
        <>
          <ScrollToTop />
          <Productspage />
        </>
      ),
    },
    {
      path: "/products/:id",
      element: (
        <>
          <ScrollToTop />
          <ProductDetailsPage />
        </>
      ),
    },
    {
      path: "/checkout",
      element: (
        <>
          <ScrollToTop />
          <CheckoutPage />
        </>
      ),
    },
    {
      path: "/cart",
      element: (
        <>
          <ScrollToTop />
          <CartPage />
        </>
      ),
    },
    {
      path: "/login",
      element: (
        <>
          <ScrollToTop />
          <LoginPage />
        </>
      ),
    },
    {
      path: "/signup",
      element: (
        <>
          <ScrollToTop />
          <SignUpPage />
        </>
      ),
    },
    {
      path: "/dashboard",
      element: (
        <>
          <ScrollToTop />
          <DashboardPage />
        </>
      ),
    },
    {
      path: "/terms-and-conditions",
      element: (
        <>
          <ScrollToTop />
          <TermsPage />
        </>
      ),
    },
    {
      path: "/faq",
      element: (
        <>
          <ScrollToTop />
          <FAQPage />
        </>
      ),
    },
    {
      path: "/blog/راهنمای تعیین سایز",
      element: (
        <>
          <ScrollToTop />
          <BlogDetailspage />
        </>
      ),
    },
    {
      path: "/purchase-guide",
      element: (
        <>
          <ScrollToTop />
          <Howbuypage />
        </>
      ),
    },
    {
      path: "/install-app",
      element: (
        <>
          <ScrollToTop />
          <InstallAppPage />
        </>
      ),
    },
  ]);

  const [cart, setCart] = useState([]);
  const [goldPrice, setgoldPrice] = useState();
  const [allPrice, setallPrice] = useState(0);

  useEffect(() => {
    getData();
  }, []);

  async function getData() {
    const res = await httpReauest("GET", "/data", {}, {});
    setgoldPrice(res.data.data.YekGram18);
  }

  return (
    <>
      <CartContext.Provider
        value={{
          cart,
          setCart,
          goldPrice,
          setgoldPrice,
          allPrice,
          setallPrice,
        }}
      >
        <RouterProvider router={router} />
       
      </CartContext.Provider>
    </>
  );
}

export default App;
