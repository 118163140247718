import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useFormik } from "formik";
import { httpReauest } from "../utils/httpRequest";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Helmet } from "react-helmet";

const SignUpPage = () => {
  const [step2, setStep2] = useState(false);
  const [acceptCode, setacceptCode] = useState();

  const VALUE_SIGN_VALIDATION = () => {
    return Yup.object({
      name: Yup.string().required("الزامی است"),
      password: Yup.string().required("الزامی است"),
      phone: Yup.string()
        .min(11, "شماره موبایل صحیح نیست")
        .max(11, "شماره موبایل صحیح نیست")
        .required("الزامی است"),
    });
  };

  const formik = useFormik({
    initialValues: { name: "", phone: "", password: "" },
    validationSchema: VALUE_SIGN_VALIDATION,
    onSubmit: async (values) => {
      await httpReauest("POST", "/user/sign", values, {})
        .then((data) => {
          if (data?.status == 201) {
            toast.success("کد تایید برای شماره ارسال شد");
            setStep2(true);
          }
        })
        .catch((data) => {
          toast.error(data?.response?.data?.message);
          formik.resetForm();
        });
    },
  });

  async function handleAccept(e) {
    e.preventDefault();
    await httpReauest(
      "POST",
      "/user/login/accept",
      { phone: formik.values.phone, code: acceptCode },
      {}
    )
      .then((dataa) => {
        if (dataa?.status == 201) {
          toast.success("کد صحیح بود");
          localStorage.setItem("user", JSON.stringify(dataa.data?.data));
          window.location.href = window.origin + "/dashboard";
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
        formik.resetForm();
      });
  }

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user?.token) {
      window.location.href = window.origin + "/dashboard";
    }
  }, []);

  return (
    <div className="hero_area">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{"دکتر گلد | ثبت نام کاربر"}</title>
        <meta
          name="description"
          content={
            "شرکت طلا و جواهر دکتر گلد تولیدکننده مدلهای فانتزی در ایران ( بازار تهران) آماده همکاری با ویترین داران و پخش"
          }
        />
        <meta
          name="og:description"
          content={
            "شرکت طلا و جواهر دکتر گلد تولیدکننده مدلهای فانتزی در ایران ( بازار تهران) آماده همکاری با ویترین داران و پخش"
          }
        />
        <meta name="og:title" content={"دکتر گلد | ثبت نام کاربر"} />
      </Helmet>
      <Header />-
      <div
        dir="rtl"
        className="py-12 flex items-center justify-center bg-white"
      >
        <div className="flex min-h-full flex-col justify-center px-6 py-6  text-right  border min-w-[380px]">
          <div className="sm:mx-auto sm:w-full sm:max-w-sm">
            <img
              alt="Your Company"
              src="/logo.png"
              className="mx-auto h-24 w-auto"
            />
            <h2 className=" text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
              ثبت نام
            </h2>
          </div>

          <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
            {!step2 ? (
              <form onSubmit={formik.handleSubmit} className="space-y-6">
                <div>
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    نام و نام خانوادگی
                  </label>
                  <div className="mt-2">
                    <input
                      id="name"
                      name="name"
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      autoComplete="name"
                      class="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    />
                    {formik.errors.name && formik.touched.name && (
                      <small className="text-danger" style={{ color: "red" }}>
                        {formik.errors.name}
                      </small>
                    )}
                  </div>
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    شماره موبایل
                  </label>
                  <div className="mt-2">
                    <input
                      id="phone"
                      name="phone"
                      value={formik.values.phone}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      autoComplete="phone"
                      class="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    />
                    {formik.errors.phone && formik.touched.phone && (
                      <small className="text-danger" style={{ color: "red" }}>
                        {formik.errors.phone}
                      </small>
                    )}
                  </div>
                </div>

                <div>
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      رمز عبور
                    </label>
                  </div>
                  <div className="mt-2">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      autoComplete="current-password"
                      class="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    />
                    {formik.errors.password && formik.touched.password && (
                      <small className="text-danger" style={{ color: "red" }}>
                        {formik.errors.password}
                      </small>
                    )}
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-[#e09145] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#e09145]/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#e09145]"
                  >
                    ارسال
                  </button>
                </div>
              </form>
            ) : (
              <form className="space-y-6">
                <div>
                  <label
                    htmlFor="code"
                    className="block text-sm font-medium leading-6 text-gray-900"
                  >
                    کدی که فرستاده شده را وارد کنید
                  </label>
                  <div className="mt-2">
                    <input
                      id="code"
                      name="code"
                      value={acceptCode}
                      onChange={(e) => setacceptCode(e.target.value)}
                      autoComplete="code"
                      class="appearance-none block w-full bg-gray-100 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    />
                  </div>
                </div>
                <div>
                  <button
                    type="button"
                    onClick={handleAccept}
                    className="flex w-full justify-center rounded-md bg-[#e09145] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#e09145]/90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#e09145]"
                  >
                    ارسال
                  </button>
                </div>
              </form>
            )}

            <p className="mt-10 text-center text-sm text-gray-500">
              قبلا اکانت ساختی؟
              <Link
                to="/login"
                className="font-semibold leading-6 text-[#e09145] hover:text-[#e09145]/90"
              >
                ورود
              </Link>
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SignUpPage;
