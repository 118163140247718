import React, { useContext, useState } from "react";
import { CiShoppingCart, CiUser } from "react-icons/ci";
import { Link } from "react-router-dom";
import { CartContext } from "../context/CartContext";
import { priceComma } from "../utils/priceComma";
import { HiHome } from "react-icons/hi";
import { GrHomeRounded } from "react-icons/gr";
import { BsWhatsapp } from "react-icons/bs";
import { AiOutlineProduct } from "react-icons/ai";

const Header = () => {
  const [show, setShow] = useState(false);

  const { cart, goldPrice } = useContext(CartContext);

  return (
    <>
      <div className="h-[78px] lg:h-[94px]"></div>
      <header className="header_section fixed top-0 left-0 bg-black w-full z-50">
        <div className="container-fluid pl-3 pr-1 lg:px-4">
          <nav className="navbar navbar-expand-lg  custom_nav-container items-center flex justify-between lg:px-5">
            <div className="flex gap-3 lg:gap-3 items-center ">
              <Link
                className=" w-12 h-12 lg:w-16 lg:h-16 bg-white rounded-full flex justify-center items-center"
                to="/"
              >
                <img src="/logo.PNG" className="w-full" alt="Dr gold Logo" />
              </Link>
              <Link
                to={"/cart"}
                className="text-white text-[22px] lg:text-[24px] relative hidden lg:inline"
              >
                <CiShoppingCart />
                <span className="w-4 h-4 text-[8px] flex justify-center items-center absolute -right-1 -top-1 bg-[#e09145] rounded-full">
                  {cart.length}
                </span>
              </Link>
              <Link
                to={"/login"}
                className="text-white text-[22px] lg:text-[24px] relative hidden lg:inline"
              >
                <CiUser />
              </Link>
              <span className="text-white text-[12px] lg:text-[14px] ms-2">
                {" "}
                قیمت روز طلا :{" "}
                <span className="text-[#e09145] mx-1  font-bold text-[14px] lg:text-[16px]">
                  {priceComma(goldPrice, 2)} تومان
                </span>
              </span>
            </div>

            <button
              class="navbar-toggler lg:hidden"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => setShow(!show)}
            >
              <span class="navbar-toggler-icon invert"></span>
            </button>

            <div
              className={`${
                show ? "max-h-52" : "max-h-0"
              } navbar-collapse overflow-hidden lg:max-h-52 duration-200`}
              id="navbarSupportedContent"
            >
              <div className="flex ml-auto  items-center pt-2 justify-center">
                <ul
                  dir="rtl"
                  className="navbar-nav flex flex-col lg:flex-row font-[500] text-[18px]"
                >
                  <li className="">
                    <Link className="nav-link" to="/">
                      خانه
                    </Link>
                  </li>
                  <li className="">
                    <Link className="nav-link" to="/about">
                      درباره ما
                    </Link>
                  </li>
                  <li className="">
                    <Link className="nav-link" to="/products">
                      محصولات
                    </Link>
                  </li>
                  <li className="">
                    <Link className="nav-link" to="/contact">
                      ارتباط با ما
                    </Link>
                  </li>
                  <li className="">
                    <Link className="nav-link" to="/terms-and-conditions">
                      قوانین و مقررات
                    </Link>
                  </li>
                  <li className="">
                    <Link className="nav-link" to="/faq">
                      سوالات متداول
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </header>
      <div
        style={{
          backgroundImage: "url(/Exclude.png)",
        }}
        className="fixed text-white bottom-0 left-0 w-full p-2 grid grid-cols-5 text-[12px] sm::text-[14px] bg-center md:bg-cover z-30 lg:hidden"
      >
        <Link
          to={"/cart"}
          className="flex flex-col justify-center items-center "
        >
          <span className="relative text-[22px] lg:text-[24px]">
            <CiShoppingCart />
            <span className="w-4 h-4 text-[8px] flex justify-center items-center absolute -right-1 -top-1 bg-[#e09145] rounded-full">
              {cart.length}
            </span>
          </span>
          <p>سبد خرید</p>
        </Link>
        <Link
          to={"/products"}
          className="flex flex-col justify-center items-center"
        >
          <AiOutlineProduct size={20} />
          <p>محصولات</p>
        </Link>

        <Link to={`tel:09131300319`}>
          <span className="icon_phone">
            <img src="/Call.png " className="w-[24px]" alt="" />
          </span>
        </Link>
        <Link to={"/login"} className="flex  flex-col justify-center items-center">
          <CiUser size={20} />
          <p>حساب کاربری</p>
        </Link>
        <Link to={"/"} className="flex flex-col justify-center items-center">
          <GrHomeRounded size={16} />
          <p className="mt-0.5">خانه</p>
        </Link>
      </div>
    </>
  );
};

export default Header;
