import { useFormik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { FaCopy, FaPlus } from "react-icons/fa6";
import { toast } from "react-toastify";
import { httpReauest } from "../utils/httpRequest";
import { BASE_URL } from "../config";
import { CartContext } from "../context/CartContext";
import { calculateGoldPrice } from "../utils/getPrice";
import { CgCopy } from "react-icons/cg";
import { BiCopy } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import copy from "clipboard-copy";
const CheckoutForm = ({ packing }) => {
  const [states, setStates] = useState();
  const [cities, setCities] = useState();
  const [payinfo, setpayinfo] = useState();
  const [checked, setchecked] = useState(false);
  const [previewImg, setPreviewImg] = useState();
  const [img, setImg] = useState();

  const navigate = useNavigate();

  const { cart, setCart, allPrice, goldPrice } = useContext(CartContext);

  function handleImg(e) {
    const fils = e.target.files[0];
    if (fils.size > 2031517) {
      toast.error("حجم فایل باید کمتر از دو مگابایت باشد");
    } else {
      setImg(fils);
      setPreviwImage(fils);
    }
  }

  async function setPreviwImage(imgInput) {
    const reader = new FileReader();
    reader.onloadend = function () {
      setPreviewImg(reader.result);
    };
    reader.readAsDataURL(imgInput);
  }

  async function getData() {
    const user = JSON.parse(localStorage.getItem("user"));

    const res = await httpReauest("GET", "/data/info", {}, {});
    setpayinfo(res.data.data);

    if (user) {
      const newData = await httpReauest(
        "GET",
        "/user/info/" + user?._id,
        {},
        { "x-access-token": user?.token }
      ).then(({ data }) => {
        const values = Object.keys(data.data.user);

        values.map((item) => {
          formik.setFieldValue(item, data.data.user[item]);
        });
      });
    }
    await fetch("https://iran-locations-api.ir/api/v1/fa/states").then(
      async (res) => {
        const data = await res.json();
        setStates(data);
      }
    );
  }

  useEffect(() => {
    getData();
  }, []);

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      address: "",
      state: "",
      city: "",
      postalCode: "",
      nationalCode: "",
    },
    onSubmit: async (values) => {
      if (!checked) {
        return toast.error("با قوانین و مقررات سایت موافقت نشده است");
      }
      if (!img) {
        return toast.error("تصویر چک پرداختی خالی است");
      }

      const keys = Object.keys(values);
      const formdata = new FormData();
      let products = [];
      cart?.map((e) => {
        products.push({
          productId: e.isSub ? null : e?._id,
          subProductId: e.isSub ? e?._id : null,
          count: e.count,
          price: calculateGoldPrice(goldPrice, e.weight, e.makingFee),
        });
      });

      const user = JSON.parse(localStorage.getItem("user"));

      if (user) {
        formdata.append("userId", user._id);
      }

      let allweights = 0;

      cart.map((item) => (allweights = allweights + item.weight));

      const priceWithoutFee =
        Math.floor((allweights * goldPrice) / 1000) * 1000;

      keys.map((item) => formdata.append(item, values[item]));
      formdata.append("img", img, img.name);
      formdata.append("allPrice", allPrice);
      formdata.append("packing", packing);
      formdata.append("goldPrice", goldPrice);
      formdata.append("priceWithoutFee", priceWithoutFee);
      formdata.append("products", JSON.stringify(products));

      await fetch(BASE_URL + "/order", {
        method: "POST",
        body: formdata,
      }).then(async (dat) => {
        if (dat?.status == 201) {
          toast.success("سفارش شما با موفقیت ثبت شد");
          navigate("/products");
        } else {
          const message = await dat.json();
          toast.error(message?.message);
        }
      });
    },
  });

  async function getCitis() {
    await fetch(
      "https://iran-locations-api.ir/api/v1/fa/cities?state=" +
        formik?.values?.state
    ).then(async (res) => {
      const data = await res.json();
      if (formik?.values?.state) {
        setCities(data[0].cities);
      } else {
        setCities(data);
      }
    });
  }
  useEffect(() => {
    if (!formik.values.city) {
      getCitis();
    }
  }, [formik.values.state]);
  return (
    <form
      onSubmit={formik.handleSubmit}
      dir="rtl"
      class="mt-10 bg-gray-50 px-4 pt-8 lg:mt-0"
    >
      <p class="text-xl font-medium">اطلاعات خرید</p>
      <p class="text-gray-400">اطلاعات خود را برای سفارش کامل کنید</p>

      <div class="">
        <div className="grid md:grid-cols-2 gap-2">
          <span>
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              نام و نام خانوادگی
            </label>
            <div class="relative">
              <input
                type="text"
                id="name"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </span>
          <span>
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              شماره تماس
            </label>
            <div class="relative">
              <input
                type="text"
                id="phone"
                name="phone"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </span>
          <span>
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              کد ملی
            </label>
            <div class="relative">
              <input
                type="text"
                id="nationalCode"
                name="nationalCode"
                value={formik.values.nationalCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </span>
          <span>
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              کد پستی
            </label>
            <div class="relative">
              <input
                type="text"
                id="postalCode"
                name="postalCode"
                value={formik.values.postalCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </span>
        </div>
        <div className="grid md:grid-cols-2 gap-2">
          <span>
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              استان
            </label>
            <div class="relative">
              <select
                id="state"
                name="state"
                value={formik.values.state}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              >
                <option></option>
                {states?.map((e) => (
                  <option>{e.name}</option>
                ))}
              </select>
            </div>
          </span>
          <span>
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              شهر
            </label>
            <div class="relative">
              <select
                id="city"
                name="city"
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={formik.values.state === ""}
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              >
                <option></option>
                {cities?.map((e) => (
                  <option>{e.name}</option>
                ))}
              </select>
            </div>
          </span>
          <span className="col-span-full">
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              آدرس
            </label>
            <div class="relative">
              <textarea
                type="text"
                id="address"
                name="address"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              />
            </div>
          </span>
          <span>
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              آپلود فیش واریز
            </label>{" "}
            <label
              htmlFor="img"
              className="relative w-full border-dashed border rounded-[10px] p-3 h-[200px] cursor-pointer border-[#444]"
            >
              {previewImg ? (
                <img
                  src={previewImg}
                  className="w-full h-full object-contain"
                  alt=""
                />
              ) : (
                <FaPlus
                  className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2"
                  size={30}
                />
              )}
            </label>
          </span>
          <span>
            <label for="email" class="mt-4 mb-2 block text-sm font-medium">
              شماره کارت واریزی
            </label>{" "}
            <div dir="ltr" class="relative">
              <input
                type="text"
                value={payinfo?.card_number}
                disabled
                class="w-full rounded-md border border-gray-200 px-3 py-3 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              />
              <span
                onClick={() => copy(payinfo?.card_number)}
                className="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer"
              >
                <BiCopy size={20} />
              </span>
            </div>
            <span className="mt-4  text-sm font-medium">
              {" "}
              {payinfo?.card_name}
            </span>
          </span>
          <span className="col-span-full">
            <p>
              از داده های شخصی شما برای پردازش سفارش شما ، پشتیبانی از تجربه شما
              در سراسر این وب سایت و برای اهداف دیگری که در سیاست حفظ حریم خصوصی
              ما شرح داده شده استفاده خواهد شد.
            </p>
            <span className="flex gap-2 mt-4 items-start">
              <p>من شرایط و مقررات سایت را خوانده ام و آن را می پذیرم</p>
              <input
                onClick={() => setchecked(!checked)}
                checked={checked}
                type="checkbox"
                className="mt-2"
              />
            </span>
          </span>

          <input
            onChange={(e) => {
              handleImg(e);
            }}
            type="file"
            className="hidden"
            id="img"
            accept="image/png, image/jpg, image/jpeg"
          />
        </div>
      </div>
      <button
        type="submit"
        class="mt-4 mb-8 w-full rounded-md bg-gray-900 px-6 py-3 font-medium text-white"
      >
        ارسال سفارش
      </button>
    </form>
  );
};

export default CheckoutForm;
