import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import ProductGallery from "../components/ProductGallery";
import Header from "../components/Header";
import SliderSimilier from "../components/SliderSimilier";
import Footer from "../components/Footer";
import { httpReauest } from "../utils/httpRequest";
import Loading from "../components/common/Loading";
import { CDN_BASE_URL } from "../config";
import { CartContext } from "../context/CartContext";
import { FaMinus, FaPlus } from "react-icons/fa6";
import { toast } from "react-toastify";
import { calculateGoldPrice } from "../utils/getPrice";
import { priceComma } from "../utils/priceComma";
import { Helmet } from "react-helmet";
import BeInIntract from "../components/BeInIntract";

const ProductDetailsPage = () => {
  const [productId, setProductId] = useState([]);
  const [subProducts, setsubProducts] = useState();
  const [loading, setloading] = useState(true);
  const [selected, setselected] = useState();
  const [onCart, setonCart] = useState();
  const [countProduct, setcountProduct] = useState(1);
  const { id } = useParams();

  const { cart, setCart, goldPrice } = useContext(CartContext);

  async function getId() {
    setloading(true);
    const response = await httpReauest("GET", `/product/${id}`, {}, {});
    setProductId(response.data.data.vehicle);
    if (response.data.data.subproducts.length) {
      setsubProducts(response.data.data.subproducts);
    }
    const isoncart = cart?.find((item) => item?._id == response.data.data._id);
    if (isoncart) {
      setonCart(isoncart);
    }
    setloading(false);
  }

  useEffect(() => {
    getId();
    window.scrollTo({ top: 0 });
  }, [id]);

  async function handleAdd() {
    if (selected) {
      if (selected.balance < countProduct) {
        return toast.error("محصول به این تعداد موجود نیست");
      }
    } else {
      if (productId.balance < countProduct) {
        return toast.error("محصول به این تعداد موجود نیست");
      }
    }

    const productCard = {
      name: productId.name,
      weight: selected ? selected.weight : productId.weight,
      makingFee: selected ? selected.makingFee : productId.makingFee,
      balance: selected ? selected.balance : productId.balance,
      _id: selected ? selected._id : productId._id,
      isSub: selected?.product ? selected?.product : false,
      img: productId.img,
      count: countProduct,
    };

    setCart((current) => [...current, productCard]);
    setonCart(true);
  }

  function handleDelete() {
    const newCart = cart.filter((e) =>
      e._id != selected ? selected?._id : productId?._id
    );
    setCart(newCart);
    setonCart(false);
  }

  function handleAddCount() {
    if (selected) {
      if (selected.balance < countProduct + 1) {
        return toast.error("محصول به این تعداد موجود نیست");
      }
    } else {
      if (productId.balance < countProduct + 1) {
        return toast.error("محصول به این تعداد موجود نیست");
      }
    }

    setcountProduct(countProduct + 1);

    const newCart = cart.filter((e) =>
      e._id != selected ? selected?._id : productId?._id
    );
    const productCard = {
      name: productId.name,
      weight: selected ? selected.weight : productId.weight,
      makingFee: selected ? selected.makingFee : productId.makingFee,
      balance: selected ? selected.balance : productId.balance,
      _id: selected ? selected._id : productId._id,
      img: productId.img,
      isSub: selected?.product ? selected?.product : false,
      count: countProduct + 1,
    };
    newCart.push(productCard);
    setCart(newCart);
  }

  function handleDecreseCount() {
    setcountProduct(countProduct - 1);
    const newCart = cart.filter((e) =>
      e._id != selected ? selected?._id : productId?._id
    );
    const productCard = {
      name: productId.name,
      weight: selected ? selected.weight : productId.weight,
      makingFee: selected ? selected.makingFee : productId.makingFee,
      balance: selected ? selected.balance : productId.balance,
      _id: selected ? selected._id : productId._id,
      img: productId.img,
      isSub: selected?.product ? selected?.product : false,
      count: countProduct - 1,
    };
    newCart.push(productCard);
    setCart(newCart);
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="hero_area">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${productId?.name ? productId?.name : ""} - دکتر گلد`}</title>
        <meta name="description" content={productId?.name} />
        <meta name="og:description" content={productId?.name} />
        <meta
          name="og:title"
          content={`${productId?.name ? productId?.name : ""} - دکتر گلد`}
        />
      </Helmet>{" "}
      <Header />
      <div className=" overflow-hidden  py-10  bg-white text-right">
        <div className="lg:grid grid-cols-2  flex flex-col justify-center items-center container">
          <div className=" w-full z-10 ">
            {productId?.images?.length ? (
              <ProductGallery images={productId?.images} img={productId?.img} />
            ) : (
              <img
                src={CDN_BASE_URL + productId?.img}
                className="w-full"
                alt={productId?.name}
              />
            )}
          </div>
          <div className="col-span-1 w-full lg:p-5">
            <h1 className="font-bold text-[18px] w-full text-right lg:text-[27px] my-2">
              {productId?.name}
            </h1>
            <div className="flex justify-between my-3 items-center">
              <h5
                dir="rtl"
                className=" font-bold text-left text-[20px] lg:text-[25px]"
              >
                <span>
                  {priceComma(
                    calculateGoldPrice(
                      goldPrice,
                      selected ? selected.weight : productId.weight,
                      selected ? selected?.makingFee : productId.makingFee
                    ),
                    2
                  )}
                </span>{" "}
                تومان
              </h5>
              <span
                dir="rtl"
                className="bg-[#e09145]/30 text-[#db8330] rounded-[5px] px-2 py-1 text-[14px] "
              >
                <>
                  {subProducts && !selected ? (
                    <> وزن را نتخاب کنید</>
                  ) : (
                    <>
                      {selected?.balance == 0 || productId?.balance == 0 ? (
                        <> ناموجود</>
                      ) : (
                        <>
                          <strong className="text-[16px]">
                            {" "}
                            {selected ? selected.balance : productId?.balance}
                          </strong>{" "}
                          عدد باقی مانده است
                        </>
                      )}
                    </>
                  )}
                </>
              </span>
            </div>

            <div dir="rtl" className="grid gap-[8px] sm:grid-cols-2 ps-4 mt-4">
              <span className="flex gap-2 relative yellow-circle-small items-center">
                <span className="font-bold whitespace-nowrap">وزن:</span>{" "}
                {subProducts ? (
                  <>
                    <div class="relative " dir="rtl">
                      <select
                        id="state"
                        name="state"
                        onChange={(e) => {
                          console.log(e.target.value);
                          e.target.value == "none"
                            ? setselected(productId)
                            : setselected(JSON.parse(e.target.value));
                        }}
                        class="w-full rounded-md border border-gray-200 px-3 py-1 pl-6 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                      >
                        <option>یک گزینه را انتخاب کنید</option>
                        <option value={"none"}>{productId?.weight} gr</option>
                        {subProducts?.map((e) => (
                          <option value={JSON.stringify(e)}>
                            {e?.weight} gr
                          </option>
                        ))}
                      </select>
                    </div>
                  </>
                ) : (
                  <span>{productId?.weight} gr</span>
                )}
              </span>
              <span className="flex gap-2 relative yellow-circle-small items-center">
                <span className="font-bold whitespace-nowrap">اجرت:</span>{" "}
                <span>20%</span>
              </span>
            </div>

            <div className="my-8">
              {onCart ? (
                <>
                  <div class="input-counter flex items-center py-3 bg-gray-100 justify-center text-[25px] gap-5 w-full">
                    <span
                      onClick={() => {
                        countProduct > 1
                          ? handleDecreseCount()
                          : countProduct == 1
                          ? handleDelete()
                          : setonCart(true);
                      }}
                      class="input-counter__minus cursor-pointer"
                    >
                      <FaMinus />
                    </span>

                    <input
                      class="w-9 text-center bg-gray-100"
                      type="text"
                      value={countProduct}
                      data-min={1}
                      data-max="1000"
                    />

                    <span
                      onClick={() => handleAddCount()}
                      class="input-counter__plus cursor-pointer"
                    >
                      <FaPlus />
                    </span>
                  </div>
                  <Link to={"/cart"} className=" slider-btn1 mt-3">
                    مشاهده سبد خرید
                  </Link>
                </>
              ) : (
                <>
                  {selected?.balance == 0 || productId?.balance == 0 ? (
                    <> <BeInIntract productId={productId?._id}/></>
                  ) : (
                    <>
                      <button
                        onClick={(e) => {
                          e.preventDefault();
                          if (subProducts && !selected) {
                            return toast.error("وزن محصول را مشخص کنید");
                          }
                          handleAdd();
                        }}
                        className=" slider-btn1"
                      >
                        افزودن به سبد
                      </button>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="col-span-full mt-6 lg:mt-16 w-full">
            <h3 className="font-bold text-[18px] lg:text-[27px]  border-b pb-3">
              توضیحات بیشتر
            </h3>
            <div
              dir="rtl"
              className="lg:text-[16px] text-[14px] pt-3"
              dangerouslySetInnerHTML={{ __html: productId?.desc }}
            />
          </div>
        </div>

        <div className="w-full bg-[#142819] px-[10px] sm:px-10  lg:px-20 mt-10">
          <span className="font-bold text-[30px] text-white lg:text-[40px] pt-8 text-center mb-6 block ">
            محصولات مشابه
          </span>
          <SliderSimilier category={productId?.category} />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProductDetailsPage;
