import { useEffect } from "react";
import { FaWhatsapp } from "react-icons/fa6";
import { Link, useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Link
        to="https://wa.me/989369650913"
        target="_blank"
        className="fixed z-50 lg:right-6 bottom-16 right-2 lg:bottom-6 bg-green-600 text-white p-[8px] text-[20px] rounded-full lg:text-[40px]"
      >
        <FaWhatsapp />
      </Link>
    </>
  );
}
