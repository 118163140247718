import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import RevealX from "./motion/RevealX";
import Reveal from "./motion/Reveal";

const Comments = [
  {
    comment:
      "سلام من از همکاران‌تون هستم یه نکته جالب که دیدم این بود که عیوب محصولات رو هم در توضیحات ذکر کردید و جای تبریک داره و خیلی سختگیرانه عیوب رو گفتین و بنظرم اینها عیب محسوب نمیشه",
  },
  {
    comment:
      "سلام عرض ادب. چقدر اجرت طلاها نسبت به بقیه همکارها مخصوصا مغازه ها مناسب‌تر هستند خدا قوتتون بده",
  },
  {
    comment:
      "سلام سفارش من امروز رسید و جدای از رنگ و لعاب طلایی که سفارش دادم باکس جذاب و متفاوتی داشته",
  },
  {
    comment:
      "سلام خوبین. چرا انقدر طلاهای شما خاص هستند و جاهای دیگه نمونه‌شون نیست؟ و ای کاش تعدادشون محدود نبود ",
  },
  { comment: "سلام مرسی که ارزش افزوده و مالیات نمیگیرید" },
  {
    comment:
      "من دستبند کارتیه از شما خریدم و چون خانه دار هستم و کار منزل زیاد انجام میدم طلاهام زود رنگ و لعابش رو از دست میداد ولی دستبند شما هنوز مثل روز اولشه و تفاوتش در کنار بقیه طلاهام کاملا مشخصه",
  },
];

const TestimonialSection = () => {
  return (
    <section className="client_section layout_padding-bottom relative bg-white py-4">
      <div className="container text-right">
        <RevealX>
          <h3 className="custom_heading text-[1.75rem]">اعتماد شما</h3>
        </RevealX>
        <Reveal>
          <p className="mt-1">نظرات کاربران در مورد دکتر گلد</p>
        </Reveal>
      </div>
      <div className="container mx-auto my-[2rem] md:px-16">
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          modules={[Autoplay]}
          autoplay={{
            delay: 3000,
          }}
          loop={true}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          cl
        >
          {Comments.map((item) => (
            <SwiperSlide>
              <Reveal>
                <div className="row text-right border-2 border-orange-300 m-2 rounded-lg shadow-md " dir="rtl">
                  <div className="col-12">
                    <div className="detail_box">
                      <div className="img_box border rounded-full bg-slate-100 p-1">
                        <img src="user.png" alt=""  />
                      </div>
                    </div>
                    <div className="detail_text">
                      <p>{item.comment}</p>
                    </div>
                  </div>
                </div>
              </Reveal>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default TestimonialSection;
