import React, { useEffect, useState } from "react";
import ProductCard from "./common/ProductCard";
import { httpReauest } from "../utils/httpRequest";
import Loading from "./common/Loading";
import Reveal from "./motion/Reveal";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";

const NewProducts = () => {
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(true);

  async function getData(params) {
    setloading(true);
    const res = await httpReauest("GET", "/product?limit=8", {}, {});
    setData(res.data.data);
    setloading(false);
  }

  useEffect(() => {
    getData();
  }, []);

  const breakpoints = {
    1024: {
      slidesPerView: 4,
      spaceBetween: 16,
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 16,
    },
    680: {
      slidesPerView: 3,
      spaceBetween: 8,
    },
    0: {
      slidesPerView: 2,
      spaceBetween: 8,
    },
  };

  return (
    <section className=" layout_padding2 text-center bg-[rgb(20,40,25)] text-white clients2">
      <div className="container">
        <div className="brand_heading">
          <Reveal head>
            <h3 className="custom_heading text-[1.75rem]">محصولات جدید</h3>
          </Reveal>
          <Reveal>
            <p className=" mt-2">
              این یک واقعیت ثابت شده است که خواننده هنگام تماشای صفحه‌آرایش با
              محتوای قابل خواندن آن حواسش پرت می‌شود. نکته استفاده از
            </p>
          </Reveal>
        </div>
      </div>
      <div className="px-[10px] sm:px-10 lg:px-20 xl:px-36 ">
        {loading ? (
          <Loading />
        ) : (
          <Swiper
            modules={[Autoplay, Pagination]}
            autoplay={{ delay: 3000 }}
            pagination={{
              el: ".swiper-pagination",
              type: "bullets",
              clickable: true,
            }}
            breakpoints={breakpoints}
            dir="ltr"
            loop={true}
            style={{paddingTop:"1.5rem"}}
          >
            {data.map((item) => (
              <SwiperSlide>
                <ProductCard white data={item} />
              </SwiperSlide>
            ))}
            <div class="swiper-pagination"></div>
          </Swiper>
        )}
      </div>
    </section>
  );
};

export default NewProducts;
