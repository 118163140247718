import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CDN_BASE_URL } from "../../config";
import Reveal from "../motion/Reveal";
import { calculateGoldPrice } from "../../utils/getPrice";
import { priceComma } from "../../utils/priceComma";
import { CartContext } from "../../context/CartContext";

const ProductCardHome = ({ data }) => {
  const { goldPrice } = useContext(CartContext);
  return (
    <Reveal height head>
      <div className="text-center shadow-xl shadow-[rgb(109,90,56)]/70 h-full relative p-0 rounded-[8px] overflow-hidden duration-200 hover:scale-105">
        <Link to={"/products/" + data?._id}>
          <div className="absolute left-0 top-0 w-full h-full bg-[#000]/50 z-10"></div>
          <img src={CDN_BASE_URL + data?.img} className="w-full " alt="" />
          <div className=" absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-white z-20 w-full">
            <h6 className="mb-3 text-[12px] sm:text-[16px] px-4">
              {data?.name}
            </h6>
            <h5 className="text-[16px] sm:text-[18px]" dir="rtl">
              <span>
                {" "}
                {priceComma(
                  calculateGoldPrice(goldPrice, data.weight, data.makingFee),
                  2
                )}
              </span>{" "}
              تومان
            </h5>
          </div>
        </Link>
      </div>
    </Reveal>
  );
};

export default ProductCardHome;
