import React, { useEffect, useState } from "react";
import MultiRangeSlider from "./common/MultirangeSlider";
import { useFormik } from "formik";
import { FaFilter } from "react-icons/fa6";
import { FaTimes } from "react-icons/fa";
import { httpReauest } from "../utils/httpRequest";
import Loading from "./common/Loading";

const Filter = ({ formik }) => {
  const [changed, setchanged] = useState(false);
  const [show, setshow] = useState(false);

  const [category, setCategory] = useState([]);
  const [loading, setloading] = useState(true);

  async function getData(params) {
    setloading(true);
    const res = await httpReauest("GET", "/category", {}, {});
    setCategory(res.data.data);
    setloading(false);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div
          className={`${
            show
              ? "left-0 h-[85vh] overflow-y-scroll pb-28 px-2 pt-2"
              : "left-full min-h-[100vh]"
          } fixed w-full top-[78px]  bg-[#fff]  lg:min-h-0 duration-200 z-20  lg:flex flex-col gap-4 lg:sticky lg:p-2  `}
        >
          <div onClick={() => setshow(false)} className="lg:hidden">
            <FaTimes size={25} className="mb-6" />
          </div>
          <h2 className="text-[20px] font-bold flex justify-between items-end ">
            دسته بندی
            {(formik.values.min_price &&
              formik.values.min_price !== 0 &&
              formik.values.mamin_pricex_price !== "") ||
            (formik.values.max_price &&
              formik.values.max_price !== 150 &&
              formik.values.max_price !== "") ||
            (formik.values.category && formik.values.category !== "") ? (
              <button
                onClick={() => formik.resetForm()}
                className="bg-red-500 text-white px-2 py-1 rounded text-[12px] font-thin"
              >
                حذف فیلتر ها
              </button>
            ) : null}
          </h2>
          <div className="grid grid-cols-2 gap-3 my-2">
            {category.map((item) => (
              <span
                onClick={() => {
                  if (formik.values.category === item._id) {
                    formik.setFieldValue("category", "");
                    setshow(false);
                  } else {
                    formik.setFieldValue("category", item?._id);
                    setshow(false);
                  }
                }}
                className={`${
                  formik.values.category === item._id
                    ? "bg-black text-[#e09145]"
                    : "border-black "
                } border  py-2 flex flex-col items-center justify-center  2xl:py-5 cursor-pointer rounded-[10px]`}
              >
                <p className={`text-[16px] font-[500] text-center mb-0`}>
                  {item?.name}
                </p>
              </span>
            ))}
          </div>
          <div className="border border-black  w-full p-4 flex flex-col gap-4 rounded-[10px]">
            <span>
              <p className=" text-[16px] font-[500] mb-3">قیمت</p>
              <MultiRangeSlider
                min={0}
                max={150}
                type="price"
                changed={changed}
                text="year:"
                formik={formik}
                names={["min_price", "max_price"]}
                onChange={({ min, max }) => {
                  formik.setFieldValue("min_price", min);
                  formik.setFieldValue("max_price", max);
                }}
              />
            </span>
          </div>
        </div>
      )}

      <div>
        <button
          onClick={() => setshow(!show)}
          className=" py-2 slider-btn1  bg-blue-500 px-8 hover:bg-blue-600 duration-300 text-white lg:hidden"
        >
          فیلتر
        </button>
      </div>
    </>
  );
};

export default Filter;
